import './App.css';
import axios from 'axios';
import React,{Component} from 'react';


class App extends Component {
  
  state = {
    // Initially, no file is selected
    selectedFile: null,
    textInput: ("")
  };
  // On file select (from the pop up)
  onFileChange = event => {
  
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };
  
  handleChange = event => {
    this.setState({textInput: event.target.value});
  }

  // On file upload (click the upload button)
  onFileUpload = () => {
  
    // Create an object of formData
    const formData = new FormData();
  
    // Update the formData object
    formData.append("myfile", this.state.selectedFile);

  
    // Details of the uploaded file
    console.log(this.state.selectedFile);
  
    // Request made to the backend api
    // Send formData object
    axios.post("http://localhost/fileupload", formData)

  };

  onTextSubmit = () => {
  
    // Create an object of formData
    const formData = new FormData();
  
    // Update the formData object
    formData.append(
      "text", this.state.textInput);
    axios.post("http://localhost/textupload", formData)
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
  
    if (this.state.selectedFile) {
       
      return (
        <div>
          <h2>File Details:</h2>
           
<p>File Name: {this.state.selectedFile.name}</p>

           
<p>File Type: {this.state.selectedFile.type}</p>

           
<p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>

        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose file or input text before pressing the Upload button</h4>
        </div>
      );
    }
  };
  
  render() {
    return (
      <div>
          <h1>
            Alpha Libertee
          </h1>
          <h3>
            Training data sumbission
          </h3>
          <div>
              <input type="text" style={{width:'100%'}} value={this.state.textInput} onChange={this.handleChange} />
              <button onClick={this.onTextSubmit}>
                Submit!
              </button>
              <div>
              <h3>
               Training file upload
              </h3>
                <div>
              <input type="file" accept="application/pdf" onChange={this.onFileChange} />
              <button onClick={this.onFileUpload}>
                Upload!
              </button>
                </div>
              </div>
          </div>
        {this.fileData()}
      </div>
    );
  }
}

export default App;
